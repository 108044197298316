a {
    color: #fff;
    text-decoration: none;
}

.me {
    width: 400px;
    margin: 90px auto;
}

.me p,
.me h1 {
    letter-spacing: 3px;
    text-align: center;
}

.me p {
    font-weight: 200;
}

.me span {
    font-weight: bold;
}
li {
    justify-content: center;
    align-items: "center";
    display: flex;
}
.social {
    position: fixed;
    top: 20%;
}

.social ul {
    padding: 0px;
    transform: translate(-58px, 0);
}

.social ul li {
    display: block;
    align-items: center;
    justify-content: center;
    margin: 5px;
    background: rgba(0, 0, 0, 0.36);
    width: 170px;
    text-align: left;
    padding: 10px;
    border-radius: 30px 0px 0px 30px;
    transition: all 1s;
}

.social ul li:hover {
    transform: translate(-110px, 0);
    background: rgba(255, 255, 255, 0.4);
}

.social ul li:hover a {
    color: #000;
}

.social ul li:hover i {
    color: #fff;
    background: rgba(0, 0, 0, 0.36);
    transform: rotate(360deg);
    transition: all 1s;
}

.social ul li i {
    margin-left: 10px;
    color: #000;
    background: #fff;
    padding: 10px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    font-size: 20px;
    background: #ffffff;
    transform: rotate(0deg);
}

